import DictaphoneUsingAI from "./DictaphoneUsingAI";
import React from "react";
import DictaphoneUsingStream from "./DictaphoneUsingStream";
import DictaphoneUsingBrowser from "./DictaphoneUsingBrowser";

const Dictaphone = ({userCredential, inListeningState, useAIForVoiceToText, voiceToTextTool, callback}) => {
    if (useAIForVoiceToText) {
        if (voiceToTextTool === 'AWS_TRANSCRIBE_STREAM') {
            return <DictaphoneUsingStream userCredential={userCredential} callback={callback}
                                          inListeningState={inListeningState}/>
        }
        if (inListeningState) {
            return <DictaphoneUsingAI tool={voiceToTextTool} callback={callback}/>
        }
    } else if (inListeningState) {
        return <DictaphoneUsingBrowser callback={callback}/>
    }

    return <></>;
};


export default Dictaphone;
