export const INITIAL = 'initial';
export const ASK_FOR_USER_NAME = 'ask-for-user-name';
export const ENTER_USER_NAME = 'enter-user-name';
export const ASK_FOR_PATIENT_NAME = 'ask-for-patient-name';
export const ENTER_PATIENT_NAME = 'enter-patient-name';
export const ASK_FOR_RELATIONSHIP_WITH_PATIENT = 'ask-for-relationship-with-patient';
export const ENTER_RELATIONSHIP_WITH_PATIENT = 'enter-relationship-with-patient';
export const ASK_FOR_DATE_OF_BIRTH = 'ask-for-date-of-birth';
export const ENTER_DATE_OF_BIRTH = 'enter-date-of-birth';
export const ASK_FOR_DRUG_NAME = 'ask-for-drug-name';
export const ENTER_DRUG_NAME = 'enter-drug-name';
export const ASK_FOR_DRUG_CONFIRMATION = 'ask-for-drug-confirmation';
export const CONFIRM_DRUG_NAME = 'confirm-drug-name';
export const ASK_TO_SPELL_DRUG = 'ask-to-spell-drug';
export const SPELL_DRUG_NAME = 'spell-drug-name';
export const ASK_FOR_DRUG_DOSAGE = 'ask-for-drug-dosage';
export const ENTER_DRUG_DOSAGE = 'enter-drug-dosage';
export const ASK_FOR_DIRECTION = 'ask-for-direction';
export const ENTER_DIRECTION = 'enter-direction';
export const ASK_FOR_DOCTOR_NAME = 'ask-for-doctor-name';
export const ENTER_DOCTOR_NAME = 'enter-doctor-name';
export const ASK_FOR_DOCTOR_PHONE_NUMBER = 'ask-for-doctor-phone-number';
export const ENTER_DOCTOR_PHONE_NUMBER = 'enter-doctor-phone-number';
export const ASK_FOR_PHARMACY_NAME = 'ask-for-pharmacy-name';
export const ENTER_PHARMACY_NAME = 'enter-pharmacy-name';
export const ASK_FOR_PHARMACY_PHONE_NUMBER = 'ask-for-pharmacy-phone-number';
export const ENTER_PHARMACY_PHONE_NUMBER = 'enter-pharmacy-phone-number';
export const ASK_FOR_PRESCRIPTION_NUMBER = 'ask-for-prescription-number';
export const ENTER_PRESCRIPTION_NUMBER = 'enter-prescription-number';
export const END = 'end';

const STATE_SPEECH_MAP = {
    [ASK_FOR_USER_NAME]: "What is your name?",
    [ASK_FOR_PATIENT_NAME]: "Say Patient's name",
    [ASK_FOR_RELATIONSHIP_WITH_PATIENT]: "What is your relationship with patient?",
    [ASK_FOR_DATE_OF_BIRTH]: "Provide patient's Date of Birth",
    [ASK_FOR_DRUG_NAME]: "Provide drug name",
    [ASK_FOR_DRUG_CONFIRMATION]: "Is drug name correct?",
    [ASK_TO_SPELL_DRUG]: "Please spell the drug name",
    [ASK_FOR_DRUG_DOSAGE]: "Provide drug dosage",
    [ASK_FOR_DIRECTION]: "Provide direction",
    [ASK_FOR_DOCTOR_NAME]: "Provide Doctor's name",
    [ASK_FOR_DOCTOR_PHONE_NUMBER]: "Provide Doctor's Phone Number",
    [ASK_FOR_PHARMACY_NAME]: "Provide pharmacy name",
    [ASK_FOR_PHARMACY_PHONE_NUMBER]: "Provide pharmacy Phone Number",
    [ASK_FOR_PRESCRIPTION_NUMBER]: "Provide prescription number",
};

export function isSpeakingState(state: string): string {
    return STATE_SPEECH_MAP[state];
}


export function isListeningState(state: string): boolean {
    return [
        ENTER_USER_NAME,
        ENTER_PATIENT_NAME,
        ENTER_RELATIONSHIP_WITH_PATIENT,
        ENTER_DATE_OF_BIRTH,
        ENTER_DRUG_NAME,
        SPELL_DRUG_NAME,
        CONFIRM_DRUG_NAME,
        ENTER_DRUG_DOSAGE,
        ENTER_DIRECTION,
        ENTER_DOCTOR_NAME,
        ENTER_DOCTOR_PHONE_NUMBER,
        ENTER_PHARMACY_NAME,
        ENTER_PHARMACY_PHONE_NUMBER,
        ENTER_PRESCRIPTION_NUMBER
    ].includes(state);
}
