import axios from "axios";

export async function postRequest(url, body, config?) {
    return await axios.post(url, body, config).then(response => response.data)
        .catch(error => {
            if (error.response) {
                console.error(error.response.status);
                throw new Error(error.response?.data || error.message);
            } else {
                throw error;
            }
        });
}

export async function getRequest(url, config) {
    return await axios.get(url, config).then(response => response.data)
        .catch(error => {
            if (error.response) {
                console.error(error.response.status);
                throw new Error(error.response?.data || error.message);
            } else {
                throw error;
            }
        });
}
