export async function checkMicrophonePermissions(): Promise<boolean> {
    let granted = false;

    await navigator.mediaDevices.getUserMedia({audio: true})
        .then(stream => {
            granted = true;
            stream.getTracks().forEach((track) => {
                if (track.readyState === 'live' && track.kind === 'audio') {
                    track.stop();
                }
            });
        }).catch(err => granted = false);

    return granted;
}

export function downloadBlob(blob: Blob, fileName: string): void {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export function delay(millisecond: number) {
    return new Promise(resolve => setTimeout(resolve, millisecond));
}
