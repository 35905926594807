import {Amplify, Auth} from 'aws-amplify';
import {CognitoUserSession} from "amazon-cognito-identity-js";

import awsConfig from '../amplify-config/aws-exports';
import {ICredentials} from "@aws-amplify/core";

Amplify.configure(awsConfig);

export async function login(loginData: { username: string, password: string }): Promise<any> {
    const user = await Auth.signIn(loginData.username, loginData.password);
    if ((user.challengeName === 'NEW_PASSWORD_REQUIRED')) {
        await setNewPassword(user, loginData.password)
    }
    return user;
}


async function setNewPassword(user: any, password: string): Promise<any> {
    return await Auth.completeNewPassword(user, password);
}

export function currentUserCredentials(): Promise<ICredentials> {
    return Auth.currentUserCredentials();
}

export function getUserSession(): Promise<CognitoUserSession> {
    return Auth.currentSession();
}

export async function isUserLoggedIn(): Promise<boolean> {
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function logout(): Promise<void> {
    await Auth.signOut();
}
