import React, {useEffect, useState} from 'react';
import {Buffer} from 'buffer';
import './Dictaphone.css';
import {recordVoice} from "../../SpeechHelper";
import {speechToText as speechToTextOpenAI} from "../../OpenAIHelper";
import {getSignedUrlListToUploadAudio, speechToTextAWS} from "../../AwsHelper";

const DictaphoneUsingAI = ({tool, callback}) => {
    const [started, setStarted] = useState(false);
    const [listening, setListening] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    async function startListening() {
        setStarted(true);
        setListening(true);
        try {
            let signedUrlDataPromise: Promise<any> = Promise.resolve();

            if (tool === 'AWS_TRANSCRIBE') {
                signedUrlDataPromise = getSignedUrlListToUploadAudio(1).then(result => result[0]);
            }

            const audioBlob = await recordVoice();
            let transcript = '';

            switch (tool) {
                case "OPEN_AI":
                    setProcessing(true)
                    transcript = await speechToTextOpenAI(Buffer.from(await audioBlob.arrayBuffer()));
                    setProcessing(false)
                    break;
                case "AWS_TRANSCRIBE":
                    const signedUrlData = await signedUrlDataPromise;
                    setProcessing(true)
                    transcript = await speechToTextAWS(signedUrlData, audioBlob);
                    setProcessing(false)
                    break;
            }

            setListening(false);
            console.log('Stop Listening');
            callback(transcript, startListening)
        } catch (err: any) {
            setErrorMessage(err.message)
            if (err.message.includes('Permission denied')) {
                console.log('Permission denied');
            }
        }
    }

    useEffect(() => {
        startListening();
        console.log('Start Listening');
    }, []);

    useEffect(() => {
        if (started && !listening) {
            setStarted(false);
        }
    }, [listening])


    useEffect(() => {
        return () => {
            console.log('Stop Listening');
        }
    }, []);

    if (errorMessage) {
        return <span>{errorMessage}</span>;
    }

    return (
        <div>
            {/*<p>{transcript}</p>*/}
            {listening &&
                (processing ?
                        <div>
                            <div className="spinner-border text-primary" role="status"></div>
                            <span style={{marginLeft: '20px'}}> Transcribing...</span>
                        </div> :
                        <div id="bars">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                )
            }
        </div>
    );
};

export default DictaphoneUsingAI;
