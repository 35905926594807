import {useEffect} from "react";
import {StartStreamTranscriptionCommand, TranscribeStreamingClient} from '@aws-sdk/client-transcribe-streaming';
import {ICredentials} from "@aws-amplify/core";
import pEvent from 'p-event';

import {LiveTranscriptionProps, MessageDataType, RecordingProperties} from "../types";


const SAMPLE_RATE = 44100;
const LANGUAGE = 'en-US';

let isInListeningState;

const startStreaming = async (
    currentCredentials: ICredentials,
    setupCallback: (config: {
        transcriptionClient: TranscribeStreamingClient,
        mediaRecorder: AudioWorkletNode
    }) => void,
    handleTranscribeOutput: (data: string, partial: boolean) => void
) => {

    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    const audioContext = new AudioContext();

    const stream: MediaStream = await window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
    });

    const mediaStreamSource = audioContext.createMediaStreamSource(stream);

    const recordingProps: RecordingProperties = {
        numberOfChannels: 1,
        sampleRate: audioContext.sampleRate,
        maxFrameCount: audioContext.sampleRate / 10
    };

    try {
        await audioContext.audioWorklet.addModule('./worklets/recording-processor.js');
    } catch (error) {
        console.log(`Add module error ${error}`);
    }
    const mediaRecorder = new AudioWorkletNode(
        audioContext,
        'recording-processor',
        {
            processorOptions: recordingProps,
        },
    );

    const destination = audioContext.createMediaStreamDestination();

    mediaRecorder.port.postMessage({
        message: 'UPDATE_RECORDING_STATE',
        setRecording: true,
    });

    mediaRecorder.port.onmessage = (event) => {
    };

    mediaStreamSource.connect(mediaRecorder).connect(destination);
    mediaRecorder.port.onmessageerror = (error) => {
        console.log(`Error receiving message from worklet ${error}`);
    };

    const audioDataIterator = pEvent.iterator<'message', MessageEvent<MessageDataType>>(mediaRecorder.port, 'message');

    const getAudioStream = async function* () {
        for await (const chunk of audioDataIterator) {
            if (isInListeningState && chunk.data.message === 'SHARE_RECORDING_BUFFER') {
                const abuffer = pcmEncode(chunk.data.buffer[0]);
                const audiodata = new Uint8Array(abuffer);
                // console.log(`processing chunk of size ${audiodata.length}`);
                yield {
                    AudioEvent: {
                        AudioChunk: audiodata,
                    },
                };
            }
        }
    };

    const transcribeClient = new TranscribeStreamingClient({
        region: 'us-east-1',
        credentials: currentCredentials,
    });

    const command = new StartStreamTranscriptionCommand({
        LanguageCode: LANGUAGE,
        MediaEncoding: 'pcm',
        MediaSampleRateHertz: SAMPLE_RATE,
        AudioStream: getAudioStream(),
    });
    const data = await transcribeClient.send(command);
    console.log('Transcribe session established ', data.SessionId);

    setupCallback({
        transcriptionClient: transcribeClient,
        mediaRecorder: mediaRecorder,
    });

    if (data.TranscriptResultStream) {
        for await (const event of data.TranscriptResultStream) {
            if (event?.TranscriptEvent?.Transcript) {
                for (const result of event?.TranscriptEvent?.Transcript.Results || []) {
                    if (result?.Alternatives && result?.Alternatives[0].Items) {
                        let completeSentence = ``;
                        for (let i = 0; i < result?.Alternatives[0].Items?.length; i++) {
                            completeSentence += ` ${result?.Alternatives[0].Items[i].Content}`;
                        }
                        // console.log(`Transcription: ${completeSentence}`);
                        handleTranscribeOutput(
                            completeSentence,
                            result.IsPartial || false
                        );
                    }
                }
            }
        }
    }
};


const stopStreaming = (
    mediaRecorder: AudioWorkletNode,
    transcribeClient: { destroy: () => void; }
) => {
    if (mediaRecorder) {
        mediaRecorder.port.postMessage({
            message: 'UPDATE_RECORDING_STATE',
            setRecording: false,
        });
        mediaRecorder.port.close();
        mediaRecorder.disconnect();
    } else {
        console.log('no media recorder available to stop');
    }

    if (transcribeClient) {
        transcribeClient.destroy();
        console.log('Transcribe session destroyed');
    }
};

const pcmEncode = (input: Float32Array) => {
    const buffer = new ArrayBuffer(input.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < input.length; i++) {
        const s = Math.max(-1, Math.min(1, input[i]));
        view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return buffer;
};

const LiveTranscriptions = (props: LiveTranscriptionProps) => {
    const {
        currentCredentials,
        setTranscript,
        onStartStreaming,
        inListeningState
    } = props;

    let transcriptionClient: TranscribeStreamingClient;
    let transcriptMediaRecorder: AudioWorkletNode;

    const onTranscriptionDataReceived = (
        data: string,
        partial: boolean
    ) => {
        setTranscript({
            channel: '0',
            partial: partial,
            text: data,
            timestamp: Date.now()
        });
    };

    const startRecording = async () => {
        if (!currentCredentials) {
            console.error('credentials not found');
            return;
        }

        try {
            console.log('startRecording');
            await startStreaming(
                currentCredentials,
                (config) => {
                    const {mediaRecorder, transcriptionClient: client} = config;
                    transcriptMediaRecorder = mediaRecorder;
                    transcriptionClient = client;
                    onStartStreaming();
                },
                onTranscriptionDataReceived
            );
        } catch (error) {
            alert(`An error occurred while recording: ${error}`);
            await stopRecording();
        }
    };

    const stopRecording = () => {
        console.log('stopRecording');
        // console.log({transcriptMediaRecorder, transcriptionClient})
        if (transcriptMediaRecorder && transcriptionClient) {
            stopStreaming(transcriptMediaRecorder, transcriptionClient);
        } else {
            console.log('no media recorder');
        }
    };

    useEffect(() => {
        startRecording();

        return () => {
            stopRecording();
        };
    }, []);

    useEffect(() => {
        isInListeningState = inListeningState
    }, [inListeningState]);

    return (
        <></>
    );
}

export default LiveTranscriptions;
