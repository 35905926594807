import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dropdown, {Option} from 'react-dropdown';
import {changeState, updateMiscellaneousData} from './app/state-slice/stateMachineSlice';
import './App.scss';
import Dictaphone from "./app/components/Dictaphone/Dictaphone";
import PrescriptionInformation from './app/components/PrescriptionInformation/PrescriptionInformation';
import {END, INITIAL, isListeningState, isSpeakingState} from './app/state-machine/States';
import {speak} from './app/SpeechHelper';
import {getNextState, processUserUtterance} from "./app/StateManager";
import {currentUserCredentials, isUserLoggedIn, login} from "./app/UserService";
import {ICredentials} from '@aws-amplify/core';
import {OPEN_AI_AVAILABLE_VOICES} from "./app/OpenAIHelper";
import {AWS_AVAILABLE_VOICES} from "./app/AwsHelper";
import {checkMicrophonePermissions} from "./app/utils";
import MicrophonePermissionExplanation
    from "./app/components/MicrophonePermissionExplanation/MicrophonePermissionExplanation";
import MicrophoneSelection from "./app/components/MicrophoneSelection/MicrophoneSelection";

function App() {
    const [userCredential, setUserCredential] = useState<ICredentials>();

    const TEXT_TO_VOICE_AI_TOOL_OPTIONS = [
        {value: 'AWS', label: 'AWS'},
        {value: 'OPEN_AI', label: 'OpenAI'},
    ];

    const VOICE_TO_TEXT_AI_TOOL_OPTIONS = [
        {value: 'OPEN_AI', label: 'OpenAI-Whisper'},
        {value: 'AWS_TRANSCRIBE', label: 'AWS-Transcribe'},
        {value: 'AWS_TRANSCRIBE_STREAM', label: 'AWS-Transcribe-Stream'}
    ];

    const currentState = useSelector((state: any) => state.stateMachine.currentState)

    const [isMicrophonePermissionProvided, setIsMicrophonePermissionProvided] = useState(false);
    const [useAIForTextToVoice, setUseAIForTextToVoice] = useState(false);
    const [useAIForVoiceToText, setUseAIForVoiceToText] = useState(false);
    const [speech, setSpeech] = useState('');
    const [voiceToTextTool, setVoiceToTextTool] = useState(VOICE_TO_TEXT_AI_TOOL_OPTIONS[0].value);
    const [textToVoiceTool, setTextToVoiceTool] = useState('');
    const [textToVoiceId, setTextToVoiceId] = useState('');


    useEffect(() => {
        isUserLoggedIn().then(async (loggedIn) => {
            if (!loggedIn) {
                await login({username: 'rohit', password: 'o4X1MzaIe9XUHZv'});
                console.log('Logged in');
            }

            setUserCredential(await currentUserCredentials());
        });
        checkMicrophonePermissions().then(granted => {
            setIsMicrophonePermissionProvided(granted);
        });
        onTextToVoiceAiToolChange(TEXT_TO_VOICE_AI_TOOL_OPTIONS[0])
    }, []);


    const dispatch = useDispatch()

    useEffect(() => {
        if (currentState === END) {
            setSpeech('');
        }

        const speech = isSpeakingState(currentState);

        if (speech) {
            setSpeech(speech);
            speak(speech).then(() => {
                const nextState = getNextState(currentState);
                if (nextState) {
                    updateState(nextState);
                }
            });
        }
    }, [currentState]);

    function startReading() {
        dispatch(updateMiscellaneousData({useAIForTextToVoice, useAIForVoiceToText, textToVoiceTool, textToVoiceId}));
        updateState(getNextState(INITIAL) as any);
    }

    function updateState(newState: string) {
        dispatch(changeState(newState))
    }

    function reset() {
        window.location.reload();
        // dispatch(resetData());
        // setSpeech('');
    }


    function onTextToVoiceAiToolChange(option: Option) {
        setTextToVoiceTool(option.value);
        setTextToVoiceId((option.value === 'OPEN_AI' ? OPEN_AI_AVAILABLE_VOICES : AWS_AVAILABLE_VOICES)[0].value);
    }

    function onChangeMicrophone(deviceId: string): void {
        dispatch(updateMiscellaneousData({microphoneDeviceId: deviceId}));
    }

    return (
        <div className="App">
            <header className="App-header">
                {isMicrophonePermissionProvided ?
                    <div>
                        {
                            currentState !== INITIAL &&
                            <div
                                className={`ai-use-status ${useAIForTextToVoice || useAIForVoiceToText ? 'yes' : 'no'}`}>
                                Text to
                                Voice:{useAIForTextToVoice ? (`${textToVoiceTool} ${textToVoiceId}`) : 'Browser API'}<br/>
                                Voice to Text:{useAIForVoiceToText ? 'AI' : 'Browser API'}<br/>
                            </div>
                        }
                        {/*<div>{currentState}</div>*/}
                        {currentState === INITIAL &&
                            <div>
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="useAIForTextToVoiceCheckbox"
                                               checked={useAIForTextToVoice}
                                               onChange={() => setUseAIForTextToVoice(!useAIForTextToVoice)}/>
                                        <label className="form-check-label" htmlFor="useAIForTextToVoiceCheckbox">
                                            Use AI for Text to Voice
                                        </label>
                                    </div>
                                    <div>
                                        {useAIForTextToVoice && <div>
                                            <Dropdown options={TEXT_TO_VOICE_AI_TOOL_OPTIONS}
                                                      onChange={onTextToVoiceAiToolChange}
                                                      value={textToVoiceTool}
                                                      placeholder="Select an option"/>
                                            <Dropdown
                                                options={textToVoiceTool === 'OPEN_AI' ? OPEN_AI_AVAILABLE_VOICES : AWS_AVAILABLE_VOICES}
                                                onChange={(option) => setTextToVoiceId(option.value)}
                                                value={textToVoiceId}
                                                placeholder="Select an option"/>
                                        </div>
                                        }
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="useAIForVoiceToTextCheckbox"
                                               checked={useAIForVoiceToText}
                                               onChange={() => setUseAIForVoiceToText(!useAIForVoiceToText)}/>
                                        <label className="form-check-label" htmlFor="useAIForVoiceToTextCheckbox">
                                            Use AI for Voice to Text
                                        </label>
                                    </div>
                                    <div>
                                        {useAIForVoiceToText &&
                                            <>
                                                <MicrophoneSelection
                                                    onChange={(deviceId: string) => onChangeMicrophone(deviceId)}/>
                                                <Dropdown options={VOICE_TO_TEXT_AI_TOOL_OPTIONS}
                                                          onChange={(option) => setVoiceToTextTool(option.value)}
                                                          value={voiceToTextTool}
                                                          placeholder="Select an option"/>
                                            </>
                                        }
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    aria-label="Start"
                                    onClick={() => startReading()}>
                                    Start
                                </button>
                            </div>
                        }
                        {currentState !== INITIAL &&
                            <button type="button"
                                    className="btn btn-danger"
                                    aria-label="Stop"
                                    onClick={() => reset()}>
                                Reset
                            </button>
                        }
                    </div>
                    : <MicrophonePermissionExplanation/>
                }

                {speech && <div>{speech}</div>}

                {![INITIAL, END].includes(currentState) &&
                    <Dictaphone userCredential={userCredential}
                                callback={processUserUtterance}
                                useAIForVoiceToText={useAIForVoiceToText}
                                voiceToTextTool={voiceToTextTool}
                                inListeningState={isListeningState(currentState)}/>
                }

                <PrescriptionInformation/>
            </header>
            <span className="version-details">Version: 1.0.6</span>
        </div>
    );
}

export default App;
