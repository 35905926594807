import React, {useEffect, useState} from 'react';
import Dropdown, {Option} from "react-dropdown";
import './MicrophoneSelection.scss';

const MicrophoneSelection = ({onChange}) => {
    const [selectedMicrophone, setSelectedMicrophone] = useState('');
    const [microphoneList, setMicrophoneList] = useState<any[]>([]);

    async function getMicrophoneList() {

        let deviceList = (await navigator.mediaDevices.enumerateDevices())
            .filter(device => device.kind === 'audioinput');

        const microphoneList: any[] = []

        for (const device of deviceList) {
            if (microphoneList.map(item => item.groupId).includes(device.groupId)) {
                continue;
            }

            microphoneList.push({
                label: device.label,
                value: device.deviceId,
                groupId: device.groupId
            });
        }

        setMicrophoneList(microphoneList);
        onChangeMicrophone(microphoneList[0]);
    }

    useEffect(() => {
        getMicrophoneList();
    }, [])

    function onChangeMicrophone(option: Option): void {
        setSelectedMicrophone(option.value);
        onChange(option.value);
    }

    return (
        <div className="microphone-selection-parent">
            <Dropdown options={microphoneList}
                      onChange={onChangeMicrophone}
                      value={selectedMicrophone}
                      placeholder="Select the microphone"/>
        </div>
    );
};

export default MicrophoneSelection;
