const awsConfig = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_dwGVQKGtD',
        userPoolWebClientId: '6laisj3tqh9c4ck6jsssdhneeg',
        identityPoolId: 'us-west-2:9cfe636b-a1a0-4d65-8143-6e09d86146cf',
        mandatorySignIn: true,
        // authenticationFlowType: 'USER_SRP_AUTH',
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
};

export default awsConfig;
