import React from 'react';

import './MicrophonePermissionExplanation.scss';

const MicrophonePermissionExplanation = () => {
    return (
        <div className="explanation-parent">
            <h1> Please provide microphone permission to use the app </h1>
            {/*<button*/}
            {/*    type="button"*/}
            {/*    className="btn btn-primary"*/}
            {/*    onClick={() => checkMicrophonePermissions()}>*/}
            {/*    Ask for Permission*/}
            {/*</button>*/}
            <h2>Google Chrome</h2>
            <ul>
                <li>1. Click on the lock icon next to the website's URL in the address bar.</li>
                <li>2. In the drop-down menu, find "Microphone" and select "Allow."</li>
                <li>3. Refresh the page to apply the changes.</li>
            </ul>

            <h2>Mozilla Firefox</h2>
            <ul>
                <li>1. Click on the lock icon in the address bar.</li>
                <li>2. Find the "Microphone" setting and choose "Allow."</li>
                <li>3. Refresh the page to apply the changes.</li>
            </ul>

            <h2>Safari (macOS)</h2>
            <ul>
                <li>1. Open Safari Settings.</li>
                <li>2. Navigate to Websites.</li>
                <li>3. Go to the Microphone tabs.</li>
                <li>4. Change access to Allow</li>
            </ul>

            <h2>Microsoft Edge</h2>
            <ul>
                <li>1. Click on the lock icon next to the URL.</li>
                <li>2. Look for "Microphone" and select "Allow."</li>
                <li>3. Refresh the page to apply the changes.</li>
            </ul>

            <p>If you encounter any issues, make sure your device's microphone is properly connected and
                functioning.</p>
        </div>
    );
};

export default MicrophonePermissionExplanation;
