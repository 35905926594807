import React, {useEffect, useState} from 'react';
import './Dictaphone.css';
import LiveTranscriptions from '../LiveTranscriptions';
import {Transcript} from '../../types';

const AUTO_STOP_TIMEOUT = 3000;

const DictaphoneUsingStream = ({userCredential, callback, inListeningState}) => {
    const [started, setStarted] = useState(false);
    const [listening, setListening] = useState(false);
    const [callbackCalled, setCallbackCalled] = useState(false);
    const [timeoutId, setTimeoutId] = useState<any>();
    const [errorMessage, setErrorMessage] = useState('');
    const [transcript, setTranscript] = useState<Transcript>();
    const [lines, setLines] = useState<Transcript[]>([]);

    useEffect(() => {
        setStarted(true);

        return () => {
            console.log('Stop Listening');
        }
    }, []);

    function startListening(): void {
        console.log('Start Listening');
        setCallbackCalled(false);
        setListening(true);
        setLines([]);
        setTranscript(undefined);
        resetTimeout();
    }

    useEffect(() => {
        console.log({inListeningState})

        if (inListeningState) {
            startListening()
        }
    }, [inListeningState]);


    function resetTimeout(): void {
        clearTimeout(timeoutId);

        const id = setTimeout(() => {
            console.log('TIMEOUT')
            setListening(false);
        }, AUTO_STOP_TIMEOUT);

        setTimeoutId(id);
    }


    useEffect(() => {
        if (inListeningState && transcript) {
            setTranscript(transcript);
            const newLines = [...lines];
            if (!transcript.partial) {
                newLines.push(transcript);
                setLines(newLines);
            }

            resetTimeout();
        }
    }, [transcript]);


    useEffect(() => {
        if (started && !listening) {
            const text = lines.map(item => item.text).join(' ') + ((transcript?.partial) ? transcript.text : '');
            // console.log({text})
            setCallbackCalled(true);
            callback(text, startListening);
        }
    }, [listening]);


    if (errorMessage) {
        return <span>{errorMessage}</span>;
    }

    return (
        <div>
            {inListeningState && !callbackCalled &&
                <div>
                    <p>{lines.map(item => item.text).join(' ') + ((transcript?.partial) ? transcript.text : '')}</p>
                    <div id="bars">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
            }
            <LiveTranscriptions
                currentCredentials={userCredential}
                inListeningState={inListeningState && !callbackCalled}
                setTranscript={setTranscript}
                onStartStreaming={() => console.log('Streaming started')}
            />
        </div>
    );
};

export default DictaphoneUsingStream;
