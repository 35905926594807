import {createSlice} from '@reduxjs/toolkit'
import {INITIAL} from "../state-machine/States";
import {Logger} from 'sass';

export const stateMachineSlice = createSlice({
    name: 'stateMachine',
    initialState: {
        currentState: INITIAL,
        prescriptionData: ([] as { name: string, value: any }[]),
        miscellaneousData: {} as any
    },
    reducers: {
        changeState: (state, action) => {
            console.log('New state:' + action.payload);
            state.currentState = action.payload
        },
        updatePrescriptionData: (state, action) => {
            const item = state.prescriptionData.find(item => item.name === action.payload.name);

            if (item) {
                item.value = action.payload.value;
            } else {
                state.prescriptionData.push(action.payload);
            }
        },
        updateMiscellaneousData: (state, action) => {
            state.miscellaneousData = {
                ...state.miscellaneousData,
                ...action.payload

            };
        },
        resetData: (state) => {
            state.currentState = INITIAL;
            state.prescriptionData = [];
            state.miscellaneousData = {};
        }
    }
})

// Action creators are generated for each case reducer function
export const {changeState, updatePrescriptionData, updateMiscellaneousData, resetData} = stateMachineSlice.actions

export default stateMachineSlice.reducer
