import Config from "../config";
import {getRequest, postRequest} from "./ApiService";
import {delay} from './utils';
// import {Buffer} from "buffer";
// import {PollyClient, SynthesizeSpeechCommand} from "@aws-sdk/client-polly";
// import {LanguageCode} from "@aws-sdk/client-transcribe-streaming";
//
// import awsConfig from '../amplify-config/aws-exports';
// import {currentUserCredentials} from "./UserService";
//
// let pollyClient: PollyClient;

export async function getSignedUrlListToUploadAudio(count: number = 1) {
    return await awsPostRequest('/audio/upload', {count});
}

export async function speechToTextAWS(presignedUrlData: any, audioBlob: Blob, language: string = 'en'): Promise<string> {
    try {
        await uploadFileToS3(presignedUrlData.preSignedUrlDetails, audioBlob, 'audio/mpeg');
        const result = await awsPostRequest('/audio/transcribe', {
            jobName: presignedUrlData.jobName,
            key: presignedUrlData.key
        });
        console.log(result);
        return result;
    } catch (err) {
        console.error('FAILED TO CONVERT SPEECH TO TEXT USING AWS:', err);
        throw err;
    }
}

async function uploadFileToS3(preSignedUrlDetails: any, audioBlob: Blob, contentType: string) {
    try {
        const {url, fields} = preSignedUrlDetails;
        const payload = new FormData();

        Object.entries(fields).forEach(([key, val]) => {
            payload.append(key, val as any);
        });

        payload.append("Content-Type", contentType);
        payload.append("file", audioBlob);

        const {data: result} = await postRequest(url, payload);
        console.log("uploaded", result);
    } catch (err) {
        console.error('FAILED TO UPLOAD FILE TO S3:', err);
        throw err;
    }
}


export async function awsGetRequest(urlPart, config = {}) {
    config = prepareConfig(config);
    return await getRequest(`${Config.API_ENDPOINT}${urlPart}`, config).then(response => response.data);
}


export async function awsPostRequest(urlPart, body, config = {}) {
    config = prepareConfig(config);
    return await postRequest(`${Config.API_ENDPOINT}${urlPart}`, body, config);
}

function prepareConfig(config: any = {}) {
    if (!config.headers) {
        config.headers = {};
    }

    config.headers['x-api-key'] = Config.API_KEY;

    return config;
}


// export async function textToSpeech(text: string, voice: string): Promise<Buffer> {
//     const params = {
//         Text: text,
//         TextType: "text",
//         Engine: 'neural',
//         VoiceId: voice,
//         OutputFormat: 'mp3',
//         LanguageCode: LanguageCode.EN_US
//     };
//
//     try {
//         if (!pollyClient) {
//             pollyClient = new PollyClient({
//                 credentials: await currentUserCredentials(),
//                 region: awsConfig.Auth.region
//             });
//         }
//
//         const response = await pollyClient.send(new SynthesizeSpeechCommand(params));
//
//         if (response?.AudioStream) {
//             const buffers: any[] = [];
//
//             // for await (const chunk of response.AudioStream as any) {
//             //     buffers.push(chunk);
//             // }
//
//             const reader = (response.AudioStream as any).getReader();
//             while (true) {
//                 const {done, value} = await reader.read();
//                 if (done) break;
//                 buffers.push(value);
//             }
//
//             return Buffer.concat(buffers);
//         } else {
//             throw new Error('No data');
//         }
//     } catch (err) {
//         console.error('FAILED TO CONVERT TEXT TO SPEECH USING POLLY:', err);
//         throw err;
//     }
// }

export async function textToSpeech(text: string, voice: string) {
    try {
        const response = await awsPostRequest('/speech/aws/generate', {
            text,
            voice
        });

        if (!response.fromCache) {
            // Url not available for few seconds.
            await delay(3000);
        }
        return response.url;
    } catch (err) {
        console.error('FAILED TO CONVERT TEXT TO SPEECH USING POLLY:', err);
        throw err;
    }
}

export const AWS_AVAILABLE_VOICES = [
    {value: 'Danielle', label: 'Danielle'},
    {value: 'Gregory', label: 'Gregory'},
    {value: 'Ivy', label: 'Ivy'},
    {value: 'Joanna', label: 'Joanna'},
    {value: 'Kendra', label: 'Kendra'},
    {value: 'Kimberly', label: 'Kimberly'},
    {value: 'Salli', label: 'Salli'},
    {value: 'Joey', label: 'Joey'},
    {value: 'Justin', label: 'Justin'},
    {value: 'Kevin', label: 'Kevin'},
    {value: 'Matthew', label: 'Matthew'},
    {value: 'Ruth', label: 'Ruth'},
    {value: 'Stephen', label: 'Stephen'}
];
