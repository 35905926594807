import React from 'react';
import {useSelector} from 'react-redux';

import './PrescriptionInformation.css';

const PrescriptionInformation = () => {
    const prescriptionData: any[] = useSelector((state: any) => state.stateMachine.prescriptionData)

    return (
        <div>
            <table className="table table-striped table-bordered">
                <tbody>
                {prescriptionData.map((item, index) => {
                    return <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.value}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    );
};

export default PrescriptionInformation;
