import store from "./store";
import {speak} from "./SpeechHelper";
import {
    ASK_FOR_DATE_OF_BIRTH,
    ASK_FOR_DIRECTION,
    ASK_FOR_DOCTOR_NAME,
    ASK_FOR_DOCTOR_PHONE_NUMBER,
    ASK_FOR_DRUG_DOSAGE,
    ASK_FOR_DRUG_NAME,
    ASK_FOR_PATIENT_NAME,
    ASK_FOR_PHARMACY_NAME,
    ASK_FOR_PHARMACY_PHONE_NUMBER,
    ASK_FOR_PRESCRIPTION_NUMBER,
    END,
    ENTER_DATE_OF_BIRTH,
    ENTER_DIRECTION,
    ENTER_DOCTOR_NAME,
    ENTER_DOCTOR_PHONE_NUMBER,
    ENTER_DRUG_DOSAGE,
    ENTER_PATIENT_NAME,
    ENTER_PHARMACY_NAME,
    ENTER_PHARMACY_PHONE_NUMBER,
    ENTER_PRESCRIPTION_NUMBER,
    INITIAL,
    ENTER_DRUG_NAME,
    SPELL_DRUG_NAME,
    ASK_FOR_DRUG_CONFIRMATION,
    CONFIRM_DRUG_NAME,
    ASK_TO_SPELL_DRUG,
    ASK_FOR_USER_NAME,
    ENTER_USER_NAME,
    ASK_FOR_RELATIONSHIP_WITH_PATIENT,
    ENTER_RELATIONSHIP_WITH_PATIENT
} from "./state-machine/States";
import {changeState, updateMiscellaneousData, updatePrescriptionData} from "./state-slice/stateMachineSlice";
import {askOpenAI} from "./OpenAIHelper";

const STATE_ORDER = [
    INITIAL,
    ASK_FOR_USER_NAME,
    ENTER_USER_NAME,
    ASK_FOR_PATIENT_NAME,
    ENTER_PATIENT_NAME,
    ASK_FOR_RELATIONSHIP_WITH_PATIENT,
    ENTER_RELATIONSHIP_WITH_PATIENT,
    ASK_FOR_DATE_OF_BIRTH,
    ENTER_DATE_OF_BIRTH,
    ASK_FOR_DRUG_NAME,
    ENTER_DRUG_NAME,
    ASK_FOR_DRUG_CONFIRMATION,
    CONFIRM_DRUG_NAME,
    ASK_TO_SPELL_DRUG,
    SPELL_DRUG_NAME,
    ASK_FOR_DRUG_CONFIRMATION,
    ASK_FOR_DRUG_DOSAGE,
    ENTER_DRUG_DOSAGE,
    ASK_FOR_DIRECTION,
    ENTER_DIRECTION,
    ASK_FOR_DOCTOR_NAME,
    ENTER_DOCTOR_NAME,
    ASK_FOR_DOCTOR_PHONE_NUMBER,
    ENTER_DOCTOR_PHONE_NUMBER,
    ASK_FOR_PHARMACY_NAME,
    ENTER_PHARMACY_NAME,
    ASK_FOR_PHARMACY_PHONE_NUMBER,
    ENTER_PHARMACY_PHONE_NUMBER,
    ASK_FOR_PRESCRIPTION_NUMBER,
    ENTER_PRESCRIPTION_NUMBER,
    END
];

export function getNextState(state: string) {
    const index = STATE_ORDER.indexOf(state);
    if (index === -1 || index === STATE_ORDER.length - 1) return null;

    return STATE_ORDER[index + 1];
}

export async function processUserUtterance(utterance: string, startListening: any) {
    if (!utterance) {
        await speak('No speech detected. Please say again.');
        startListening();
        return;
    }

    const {currentState, miscellaneousData, prescriptionData} = store.getState().stateMachine;
    const nextState = getNextState(currentState);

    switch (currentState) {
        case ENTER_USER_NAME:
            store.dispatch(updatePrescriptionData({name: "Your Name", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_PATIENT_NAME:
            store.dispatch(updatePrescriptionData({name: "Patient's Name", value: utterance}));
            const yourName = prescriptionData.find(item => item.name === "Your Name")?.value;
            if (yourName === utterance) {
                store.dispatch(changeState(ASK_FOR_DATE_OF_BIRTH));
            } else {
                store.dispatch(changeState(ASK_FOR_RELATIONSHIP_WITH_PATIENT));
            }
            break;
        case ENTER_RELATIONSHIP_WITH_PATIENT:
            store.dispatch(updatePrescriptionData({name: "Relationship with Patient", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_DATE_OF_BIRTH:
            const {dateOfBirth} = await askOpenAI(`Extract the date of birth in MM-DD-YYYY format from the following text: "${utterance}". Json response with key 'dateOfBirth'.  If not available, return null`, true);
            if (!dateOfBirth) {
                await speak('Not a valid date of birth. Please say again.');
                startListening();
                return;
            }
            store.dispatch(updatePrescriptionData({name: "Date of Birth", value: dateOfBirth}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_DRUG_NAME:
        case SPELL_DRUG_NAME:
            utterance = utterance.replace(/ /g, '');
            const {medicineName} = await askOpenAI(`I have a word, and I need to determine if it's the name of a medicine. If it is, return name of the medicine as it is. If not, response with the name of the most probable medicine that closely matches or relates to this word. Json response with key 'medicineName'. The word is:${utterance}`, true);
            await speak(medicineName);
            store.dispatch(updatePrescriptionData({name: "Drug name", value: medicineName}));
            store.dispatch(changeState(nextState));
            break;
        case CONFIRM_DRUG_NAME:
            if (utterance.toLowerCase().includes('yes')) {
                store.dispatch(changeState(ASK_FOR_DRUG_DOSAGE))
            } else {
                const drugNameCheckCount = (miscellaneousData.drugNameCheckCount || 0) + 1;
                store.dispatch(updateMiscellaneousData({drugNameCheckCount}));

                if (drugNameCheckCount >= 2) {
                    store.dispatch(changeState(ASK_TO_SPELL_DRUG));
                } else {
                    store.dispatch(changeState(ASK_FOR_DRUG_NAME))
                    startListening();
                    return;
                }
            }
            break
        case ENTER_DRUG_DOSAGE:
            store.dispatch(updatePrescriptionData({name: "Drug Dosage", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_DIRECTION:
            store.dispatch(updatePrescriptionData({name: "Direction", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_DOCTOR_NAME:
            store.dispatch(updatePrescriptionData({name: "Doctor's Name", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_DOCTOR_PHONE_NUMBER:
            utterance = utterance.replace(/ /g, '');
            if (!await isPhoneNumberValid(utterance)) {
                await speak('Not a valid phone number. Please say again.');
                startListening();
                return;
            }
            store.dispatch(updatePrescriptionData({name: "Doctor's Phone Number", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_PHARMACY_NAME:
            store.dispatch(updatePrescriptionData({name: "Pharmacy Name", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_PHARMACY_PHONE_NUMBER:
            utterance = utterance.replace(/ /g, '');
            if (!await isPhoneNumberValid(utterance)) {
                await speak('Not a valid phone number. Please say again.');
                startListening();
                return;
            }
            store.dispatch(updatePrescriptionData({name: "Pharmacy Phone Number", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
        case ENTER_PRESCRIPTION_NUMBER:
            store.dispatch(updatePrescriptionData({name: "Prescription Number", value: utterance}));
            store.dispatch(changeState(nextState));
            break;
    }
}

async function isPhoneNumberValid(phoneNumber: string): Promise<boolean> {
    const {valid} = await askOpenAI(`Check if following number is valid phone number.: "${phoneNumber}". Json response with key 'valid' and boolean value.`, true);
    return valid;
}
