import OpenAI from "openai";
import {toFile} from 'openai/uploads';
import {Buffer} from "buffer";
import Config from "../config";
import {awsPostRequest} from "./AwsHelper";

const openai = new OpenAI({
    apiKey: Config.OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});


const OPENAI_MODEL = "gpt-3.5-turbo";
const MAX_TOKENS = 100;

export async function askOpenAI(prompt: string, jsonResponse: boolean = false) {

    let completion: any;

    try {
        const requestBody: any = {
            messages: [{role: 'user', content: prompt}],
            model: OPENAI_MODEL,
            max_tokens: MAX_TOKENS
        };

        if (jsonResponse) {
            requestBody.response_format = {type: "json_object"};
        }

        completion = await openai.chat.completions.create(requestBody);
        const content = completion.choices[0].message.content
        return jsonResponse ? JSON.parse(content) : content;
    } catch (e) {
        console.error('FAILED TO ASK OPEN AI:', e);
        console.log(`Prompt Text: ${prompt}`);
        if (completion) {
            console.log(`OpenAI response: ${JSON.stringify(completion)}`);
        }
        throw e;
    }
}

// export async function textToSpeech(text: string, voice: string) {
//     try {
//         const mp3 = await openai.audio.speech.create({
//             model: "tts-1",
//             voice: voice as any,
//             input: text
//         });
//         return Buffer.from(await mp3.arrayBuffer());
//     } catch (err) {
//         console.error('FAILED TO CONVERT TEXT TO SPEECH USING OPEN-AI:', err);
//         throw err;
//     }
// }

export async function textToSpeech(text: string, voice: string) {
    try {
        const response = await awsPostRequest('/speech/open-ai/generate', {
            text,
            voice
        });
        return response.url;
    } catch (err) {
        console.error('FAILED TO CONVERT TEXT TO SPEECH USING OPEN-AI:', err);
        throw err;
    }
}

export async function speechToText(audioBuffer: Buffer, language: string = 'en'): Promise<string> {
    try {
        const response = await openai.audio.transcriptions.create({
            file: await toFile(audioBuffer, 'audio.mp3'),
            model: 'whisper-1',
            language
        });

        return response.text;
    } catch (err) {
        console.error('FAILED TO CONVERT SPEECH TO TEXT:', err);
        throw err;
    }
}

export const OPEN_AI_AVAILABLE_VOICES = [
    {value: 'alloy', label: 'Alloy'},
    {value: 'echo', label: 'Echo'},
    {value: 'fable', label: 'Fable'},
    {value: 'onyx', label: 'Onyx'},
    {value: 'nova', label: 'Nova'},
    {value: 'shimmer', label: 'Shimmer'},
];
