import React, {useEffect, useState} from 'react';
import SpeechRecognition, {useSpeechRecognition} from '../../../libs/react-speech-recognition';
import './Dictaphone.css';

const DictaphoneUsingBrowser = ({callback}) => {
    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    const [started, setStarted] = useState(false);

    async function startListening() {
        await SpeechRecognition.startListening({continuous: undefined, language: 'en-US'});
        setStarted(true);
    }

    useEffect(() => {
        if (browserSupportsSpeechRecognition) {
            startListening();
            console.log('Start Listening');
        }
    }, []);

    useEffect(() => {
        if (started && !listening) {
            setStarted(false);
            callback(transcript, startListening);
        }
    }, [listening])


    useEffect(() => {
        return () => {
            if (browserSupportsSpeechRecognition) {
                SpeechRecognition.stopListening();
                console.log('Stop Listening');
            }
        }
    }, []);

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }


    return (
        <div>
            {/*<p>Microphone: {listening ? 'on' : 'off'}</p>*/}
            <p>{transcript}</p>
            {listening && <div id="bars">
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            }
        </div>
    );
};

export default DictaphoneUsingBrowser;
