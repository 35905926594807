import {textToSpeech as textToSpeechUsingOpenAi} from "./OpenAIHelper";
import {textToSpeech as textToSpeechUsingAWS} from "./AwsHelper";
import OpusMediaRecorder from "opus-media-recorder";
import store from "./store";

const voices = speechSynthesis.getVoices();

let audioElement: HTMLAudioElement;

export async function speak(text: string) {
    const {useAIForTextToVoice, textToVoiceTool, textToVoiceId} = store.getState().stateMachine.miscellaneousData;

    if (useAIForTextToVoice) {
        console.log(`Speak:${text}`)

        if (textToVoiceTool === 'OPEN_AI') {
            await speakUsingOpenAI(text, textToVoiceId);
        } else {
            await speakUsingAWS(text, textToVoiceId);
        }
    } else {
        await speakUsingBrowser(text);
    }
}

export async function recordVoice(autoStopTimeout = 2000): Promise<Blob> {
    const {microphoneDeviceId} = store.getState().stateMachine.miscellaneousData;

    console.log(`Using microphone: ${microphoneDeviceId}`);

    const MIN_DECIBELS = -39;

    return navigator.mediaDevices.getUserMedia({
        audio: {
            deviceId: microphoneDeviceId
        }
    }).then(stream => {
        console.log('Recording started');

        // Choose desired format like audio/webm. Default is audio/ogg
        const options = {mimeType: 'audio/webm'}
        const workerOptions = {
            encoderWorkerFactory: function () {
                // UMD should be used if you don't use a web worker bundler for this.
                return new Worker('assets/encoderWorker.umd.js')
            },
            WebMOpusEncoderWasmPath: 'WebMOpusEncoder.wasm'
        };

        const mediaRecorder = new OpusMediaRecorder(stream, options, workerOptions);
        mediaRecorder.start();

        let lastSoundTimestamp = Date.now();

        const audioChunks: any[] = [];
        mediaRecorder.addEventListener("dataavailable", event => {
            audioChunks.push(event.data);
        });

        const audioContext = new AudioContext();
        const audioStreamSource = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyser.minDecibels = MIN_DECIBELS;
        audioStreamSource.connect(analyser);

        const bufferLength = analyser.frequencyBinCount;
        const domainData = new Uint8Array(bufferLength);

        let soundDetected = false;

        const detectSound = () => {
            soundDetected = false

            analyser.getByteFrequencyData(domainData);

            for (let i = 0; i < bufferLength; i++) {
                if (domainData[i] > 0) {
                    soundDetected = true
                }
            }

            if (!soundDetected) {
                console.log(`No Sound for :${Date.now() - lastSoundTimestamp}`)
            }

            if (soundDetected) {
                lastSoundTimestamp = Date.now()
            } else {
                if (Date.now() - lastSoundTimestamp > autoStopTimeout) {
                    mediaRecorder.stop();
                    return;
                }
            }
            window.requestAnimationFrame(detectSound);
        };

        window.requestAnimationFrame(detectSound);

        return new Promise((resolve, reject) => {
            mediaRecorder.addEventListener("error", reject)

            mediaRecorder.addEventListener("stop", () => {
                console.log('Recording stopped');
                const audioBlob = new Blob(audioChunks);
                resolve(audioBlob);
            });
        })
    });
}

async function speakUsingBrowser(text: string) {
    return new Promise((resolve, reject) => {
        // Create a SpeechSynthesisUtterance
        const utterance = new SpeechSynthesisUtterance(text);

        // Select a voice
        utterance.voice = voices[0]; // Choose a specific voice

        // Speak the text
        speechSynthesis.speak(utterance);

        utterance.onend = function (event) {
            resolve(null)
        };

        utterance.onerror = function (event) {
            console.error('Failed to speak:', event);
            reject(event.error);
        };
    });
}

async function speakUsingOpenAI(text: string, voice: string) {
    try {
        const url = await textToSpeechUsingOpenAi(text, voice);
        await playAudioFromUrl(url)
    } catch (err) {
        console.error('Failed to speak using OpenAI:', err);
    }
}

async function speakUsingAWS(text: string, voice: string) {
    try {
        const url = await textToSpeechUsingAWS(text, voice);
        // const blob = new Blob([buffer], {"type": "audio/mpeg"});
        await playAudioFromUrl(url)
    } catch (err) {
        console.error('Failed to speak using aws:', err);
    }
}

export async function playAudioBlob(audioBlob: Blob) {
    const audioURL = window.URL.createObjectURL(audioBlob);
    return await playAudioFromUrl(audioURL);
}

export async function playAudioFromUrl(audioURL: string) {
    if (!audioElement) {
        audioElement = new Audio();
    }
    audioElement.src = audioURL;

    return await new Promise((resolve, reject) => {
        const endedListener = (event: any) => {
            resolve(event);
            audioElement.replaceWith(audioElement.cloneNode(true));
        };
        audioElement.addEventListener('ended', endedListener, false);
        audioElement.addEventListener('error', reject, false);
        audioElement.play();
    })
}
